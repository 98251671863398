<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
        <!-- [D] 가로/세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer-message">
                <div class="layer-message__top">
                    <h3 class="layer-message__title">
	                    {{res_message}}
                    </h3>
                </div> 
                <div class="layer-message__btns">
                    <button type="button" v-if="type === '14'" class="btn" @click="handleClose">
                        {{t('10250')}}
                    </button>
                    <button type="button" v-else class="btn skyblue" @click="handleClose">
                        {{t('10143')}}
                    </button>
                    <button type="button" v-if="type === '14'" class="btn skyblue" @click="handleClick">
                        {{t('10295')}}
                    </button>
                </div>
			</div>
		</div>
	</section>
</template>

<script>
import { onBeforeRouteLeave } from 'vue-router';
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

export default {
    props: ["handleClose", "type", "handleClick"],
    setup(props) {
		const store = useStore();
		const res_message = computed(() => store.state.app.res_message);
        const { t }= useI18n() 
        //console.log(res_message.value)
        onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
        return {
            res_message,
             t,  //번역필수 모듈
		  i18n, 
        }
    },
}
</script>